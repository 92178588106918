.default-layout {
  display: gird;
  grid-template-rows: 80px auto 3rem;
  grid-template-areas:
    "header"
    "main"
    "footer";
}

header {
  grid-area: header;
}

main {
  grid-area: main;
  min-height: 88vh;
}

footer {
  grid-area: footer;
  background: black;
  padding: 1rem;
  color: white;
}
.btn-outline-info.disabled, .btn-outline-info:disabled, .btn-outline-info.disabled:hover, .btn-outline-info:disabled:hover {
  color: #a7a7a7!important;
  border-color: #a7a7a7;
}