.entry-page {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-box {
  box-shadow: 0px 0px 15px -5px black;
}
.jumbotron{
  margin-top:1rem ;
  margin-bottom:1rem ;
}